import { render, staticRenderFns } from "./PersonalData.vue?vue&type=template&id=7ace3b15&scoped=true"
import script from "./PersonalData.vue?vue&type=script&lang=js"
export * from "./PersonalData.vue?vue&type=script&lang=js"
import style0 from "./PersonalData.vue?vue&type=style&index=0&id=7ace3b15&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ace3b15",
  null
  
)

export default component.exports