import { render, staticRenderFns } from "./LatestAnnouncement2.vue?vue&type=template&id=01be8a2a&scoped=true"
import script from "./LatestAnnouncement2.vue?vue&type=script&lang=js"
export * from "./LatestAnnouncement2.vue?vue&type=script&lang=js"
import style0 from "./LatestAnnouncement2.vue?vue&type=style&index=0&id=01be8a2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01be8a2a",
  null
  
)

export default component.exports