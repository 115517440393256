<template>
  <div class="BOX">
    <div class="one-box3">
      <div class="one-box2-box">
       <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box2">{{language11.a11}}</div>
      <div class="kong">&nbsp;</div>
    </div>
    <div class="one-box">
      <div class="one-box1">
        <div class="center">&nbsp;&nbsp;&nbsp;{{language11.b11}}</div>
        <div class="center">{{ username }} &nbsp;&nbsp;&nbsp;</div>
      </div>
  
      <div class="one-box1">
        <div class="center">&nbsp;&nbsp;&nbsp;{{language11.c11}}</div>
        <div class="center">{{ bankname }}&nbsp;&nbsp;&nbsp;</div>
      </div>
      
      <div class="one-box1">
        <div class="center">&nbsp;&nbsp;&nbsp;{{language11.d11}}</div>
        <div class="center">{{ bankcard }}&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="xhx"></div>

      <!-- <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;777</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;777</div></div>
        <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;888</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核中</div></div> -->
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from  "../components/Menu.vue"

export default {
  components:{
    Menu
  },
  data() {
    return {
      bankname: "",
      bankcard: "",
      username: "",
      items: [
        {
          name: "555,",
          name1: "666",
          name2: "777",
          name3: "888",
          name4: "999",
          name5: "000",
        },
      ],
      // 翻译数组
      language11: {
        a11: "收件人账号",
        b11: "账户：",
        c11: "银行名称：",
        d11: "银行卡号：",
      },
      languageChinese11: {
        a11: "收件人账号",
        b11: "账户：",
        c11: "银行名称：",
        d11: "银行卡号：",
      },
      languageEnglish11: {
        a11: "Recipient Account",
        b11: "account:",
        c11: "Bank Name:",
        d11: "Bank Card Number:",
      },
      //繁体中文数组
      languageJT11: {
        a11: "收件人帳號",
        b11: "帳號：",
        c11: "銀行名稱：",
        d11: "銀行卡號：",
      },
      //泰语数组
      languageTY11: {
        a11: "บัญชีผู้รับ",
        b11: "บัญชี:",
        c11: "ชื่อธนาคาร:",
        d11: "หมายเลขบัตรธนาคาร:",
      },

      //韩语数组
      languageHY11: {
        a11: "수신계좌",
        b11: "계정:",
        c11: "은행명:",
        d11: "은행카드번호:",
      },

      //日语数组
      languageRY11: {
        a11: "受信者アカウント",
        b11: "アカウント：",
        c11: "銀行名：",
        d11: "銀行カード番号：",
      },
      languageYDY11: {
        a11: "प्राप्तकर्ता खाता",
        b11: "खाता:",
        c11: "बैंक का नाम:",
        d11: "बैंक कार्ड नंबर:",
      },

      //印度语数组
    };
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language11 = this.languageChinese11;
    }
    if (localStorage.getItem("language") == "english") {
      this.language11 = this.languageEnglish11;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language11 = this.languageJT11;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language11 = this.languageHY11;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language11 = this.languageTY11;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language11 = this.languageYDY11;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language11 = this.languageRY11;
    }

    this.getBankCard();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // zhuanhuan(jc){
    //   if(jc.length==10){
    //   let star =jc.substring(0,3);
    //   let middleStars ="****";
    //   let end = jc.substring(7,11);
    //   this.bankcard = star + middleStars+end;
    //   }
    //   if(jc.length==11){
    //   let star =jc.substring(0,3);
    //   let middleStars ="*****";
    //   let end = jc.substring(8,12);
    //   this.bankcard = star + middleStars+end;
    //   }
    //   if(jc.length==12){
    //   let star =jc.substring(0,3);
    //   let middleStars ="*****";
    //   let end = jc.substring(9,13);
    //   this.bankcard= star + middleStars+end;
    //   }

    // },
    getBankCard() {
      let token = sessionStorage.getItem("token");
      axios
        .get(`/userbank/single.do?uid=${token}`)
        .then((res) => {
          // //console.log(res);
          try {
            if (res.data.code == 200) {
              this.bankname = res.data.data.bankname;
              this.username = res.data.data.name;
              //  this.bankcard = res.data.data.bankcode;
               this.bankcard=res.data.data.bankcode
              // //console.log(this.bankcard, this.bankname);
            }
          } catch (error) {}
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  margin-top: 0px;
  background-color: rgb(217, 222, 222);
}

.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;


  background-color: rgb(217, 222, 222);
}

.one-box1 {
  border-top: 1px  solid rgb(197, 200, 200);
  display: flex;
  height: 50px;
  color:  rgb(149, 150, 150);;
 
}

.one-box2 {
  display: flex;
  width: 100%;
}

.one-box3 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px;
  background-color: rgb(255, 255, 255);
}

.one-box2-box2 {
  display: flex;
  justify-content: center;
  width: 50%;
}

.pic-back {
  width: 10px;
  height: auto;
  
}

.box-2-2 {
  width: 100%;
}

.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}

.back2-pick {
  width: 20px;
  height: 20px;
}

.center {
  display: flex;
  align-items: center;
}

</style>