<template>
  <div class="BOX">
    <div class="wh"></div>
    <div class="one-box3">
      <div class="one-box2-box">
        <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="blck"
        />
      </div>
      <div class="one-box2-box2">{{ language.a }}</div>
      <div>&nbsp;</div>
    </div>

    <div class="wh"></div>
    <div class="one-box" v-for="(item, index) in items" :key="index">
      <div>&nbsp;</div>
      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.b }}&nbsp;
        </div>
        <div class="box-2-2">{{ item.ctime }}</div>
      </div>
      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.c }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ item.amount }}
        </div>
      </div>
      <div class="one-box1">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.d }}&nbsp;
        </div>
        <div class="box-2-2">
          {{ language.f }}
        </div>
      </div>
      <div class="one-box1" v-if="item.status == language.g">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.e }}&nbsp;
        </div>
        <div class="box-2-2" style="color: orange">
          {{ item.status }}
        </div>
      </div>

      <div class="one-box1" v-if="item.status == language.i">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.e }}&nbsp;
        </div>
        <div class="box-2-2" style="color: red">
          {{ item.status }}
        </div>
      </div>

      <div class="one-box1" v-if="item.status == language.h">
        <div class="one-box2">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ language.e }}&nbsp;
        </div>
        <div class="box-2-2" style="color: green">
          {{ item.status }}
        </div>
      </div>

      <!-- <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;777</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;777</div></div>
        <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;888</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核中</div></div> -->
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue";
export default {
  components: {
    Menu,
  },
  data() {
    return {
      items: [],
      // 翻译数组
      language: {
        a: "充值记录",
        b: "时间:",
        c: "金额:",
        d: "类型:",
        e: "状态:",
        f: "银行转账",
        g: "审核中",
        h: "成功",
        i: "扣款",
      },
      languageChinese: {
        a: "充值记录",
        b: "时间:",
        c: "金额:",
        d: "类型:",
        e: "状态:",
        f: "银行转账",
        g: "审核中",
        h: "成功",
        i: "扣款",
      },
      languageEnglish: {
        a: "Recharge Record",
        b: "Time:",
        c: "Amount:",
        d: "Type:",
        e: "Status:",
        f: "Bank Transfer",
        g: "Under Review",
        h: "Success",
        i: "Deduction",
      },
      //繁体中文数组
      languageJT: {
        a: "充值記錄",
        b: "時間:",
        c: "金額:",
        d: "類型:",
        e: "狀態:",
        f: "銀行轉匯",
        g: "審核中",
        h: "成功",
        i: "扣款",
      },
      //泰语数组
      languageTY: {
        a: "บันทึกการเติมเงิน",
        b: "เวลา:",
        c: "วงเงิน:",
        d: "ประเภท:",
        e: "สถานะ:",
        f: "ระบบสั่งการ",
        g: "ในการตรวจสอบ",
        h: "เติมเงินสำเร็จ",
        i: "หักเงิน",
      },

      //韩语数组
      languageHY: {
        a: "충전기록",
        b: "시간:",
        c: "금액:",
        d: "유형:",
        e: "상태:",
        f: "은행송금",
        g: "심사중",
        h: "성공",
        i: "결제",
      },

      //日语数组
      languageRY: {
        a: "チャージ履歴",
        b: "時間:",
        c: "金額:",
        d: "種別:",
        e: "状態:",
        f: "銀行振込",
        g: "処理中",
        h: "成功",
        i: "失敗",
      },
      languageYDY: {
        a: "रिचार्ज रिकॉर्ड",
        b: "समय:",
        c: "राशि:",
        d: "प्रकार:",
        e: "स्थिति:",
        f: "बैंक ट्रांसफर",
        g: "रिভিजन में",
        h: "सफल",
        i: "डेबिट",
      },

      //印度语数组
    };
  },
  mounted() {
    this.Rechar();
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
  },
  methods: {
    blck() {
      this.$router.go(-1);
    },

    Rechar() {
      let token = sessionStorage.getItem("token");
      axios
        .get(`/recharge/htallmy.do?uid=${+token}`)
        .then((res) => {
          //console.log(res);
          this.items = res.data.data.reverse();
          this.items.forEach((elm) => {
            if (elm.states == "0") {
              elm.states = this.language.g;
            } else if (elm.states == "1") {
              elm.status = this.language.h;
            } else if (elm.states == "2") {
              elm.status = this.language.i;
            }
          });
        })
        .catch();
    },
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 0px;
  box-sizing: border-box;
  padding-bottom: 60px;
  background-color: rgb(255, 255, 255);
}
.one-box {
  border: 1px solid rgb(187, 180, 180);
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eaeaea;
  border-left: none;
  border-right: none;
}
.one-box1 {
  display: flex;
  width: 100%;
  height: 30px;
  color: rgb(144, 148, 148);
  line-height: 30px;
}
.one-box2 {
  display: flex;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
}
.one-box3 {
  display: flex;
  width: 100%;
  justify-content: space-around;

}

.one-box2-box {
 
  display: flex;
  align-items: center;
}
.one-box2-box2 {
  display: flex;
  justify-content: center;
  width: 50%;
}
.pic-back {
  width: 10px;
  widows: 10px;
}
.box-2-2 {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
}
.wh {
  width: 100%;

  height: 10px;
}
.wh1 {
  width: 100%;
  height: 10px;
}
</style>