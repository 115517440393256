<template>
  <div class="BOX">
    <div class="one-box3">
      <div class="one-box2-box">
        <button>
          <img
            src="../assets/img/lblack.png"
            alt=""
            class="pic-back"
            @click="back"
          />
        </button>
      </div>
      <div class="one-box2-box">
        <p>{{language.b}}</p>
      </div>
      <div></div>
    </div>
    <div class="MessageBox">
      <p>{{ language.a }}</p>
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu
  },
  methods: {
    Modifylogin() {
      this.$router.push("/Modifylogin");
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
  },

  data() {
    return {
      items: [
        {
          name: "555,",
          name1: "666",
          name2: "777",
          name3: "888",
          name4: "999",
          name5: "000",
        },
      ],
      // 翻译数组
      language: {
        a: "曼谷证券交易所投资和财富管理它是泰国证券交易所（SET）的子公司，其主要业务是投资和财富管理以及各种加密货币交易。",
        b: "公司简介",
      },
      languageChinese: {
        a: "曼谷证券交易所投资和财富管理它是泰国证券交易所（SET）的子公司，其主要业务是投资和财富管理以及各种加密货币交易。",
        b: "公司简介",
      },
      languageEnglish: {
        a: "Bangkok Stock Exchange Investment and Wealth Management is a subsidiary of the Stock Exchange of Thailand (SET), whose main business is investment and wealth management as well as various cryptocurrency transactions.",
        b: "Company Introduction",
      },
      //繁体中文数组
      languageJT: {
        a: "曼谷證券交易所投資和財富管理是泰國證券交易所（SET）的子公司，其主要業務是投資和財富管理以及各種加密貨幣交易。",
        b: "公司簡介",
      },
      //泰语数组
      languageTY: {
        a: "ตลาดหลักทรัพย์กรุงเทพการลงทุนและการบริหารความมั่งคั่ง ประเทศไทย เป็นบริษัทย่อยของตลาดหลักทรัพย์แห่งประเทศไทย (SET.) ธุรกิจหลักคือการลงทุนและการบริหารความมั่งคั่งและการทำธุรกรรมสกุลเงินดิจิทัลต่างๆ",
        b: "ข้อมูล บริษัท ",
      },

      //韩语数组
      languageHY: {
        a: "방콕 증권거래소 투자 및 자산 관리 태국 증권거래소(SET)의 자회사로, 투자 및 자산 관리와 다양한 암호화폐 거래를 주요 사업으로 하고 있습니다.",
        b: "회사 프로필",
      },

      //日语数组
      languageRY: {
        a: "バンコク証券取引所投資・財富管理事業部は、タイ証券取引所（SET）の子会社で、主な事業内容は投資・財富管理業務および各種暗号通貨取引業務です。",
        b: "会社概要",
      },
      languageYDY: {
        a: "बैंकॉक स्टॉक एक्सचेंज इन्वेस्टमेंट ऐंड वेल्थ मैनेजमेंट, थाईलैंड स्टॉक एक्सचेंज (SET) का एक सहायक कंपनी है, जिसका मुख्य व्यवसाय निवेश, धन प्रबंधन तथा विभिन्न प्रकार के क्रिप्टोकरेंसी ट्रेडिंग है。",
        b: "कंपनी परिचय",
      },

      //印度语数组
    };
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
  },
};
</script>

<style scoped>
img{
  padding-left: 10px;
}
body {
  padding: 0px;
  margin: 0px;
}

.MessageBox {
  width: 100%;
  height: 10%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  flex-direction: column;
 
}

.MessageBox p {
  width: 100%;
  height: 100%;
  /* border:1px solid rebeccapurple; */
  /* margin-left: 10px; */
  color: rgb(159, 152, 152);
   font-size: 14px;
}

.BOX {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 700px;
  /* margin-top: 0px; */
  /* border-radius:10px 10px 10px 10px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(223, 224, 224);
}

.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;

  background-color: rgb(217, 222, 222);
}

.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
}

.one-box2 {
  display: flex;
  width: 100%;
}

.one-box3 {
  display: flex;
  /* space-around:space-evenly; */
  justify-content: space-between;
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 10px 0;
}

.one-box2-box {
  width: 25%;
  height: 100%;
  /* border:1px solid red; */
  display: fixed;
  justify-content: space-between;
  /* margin-right:35%; */
}

.one-box2-box p {
  width: 100%;
  height: 100%;
  /* border:1px solid red; */
  margin: 0px 0px 0px 0px;
}
.one-box2-box button {
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  background-color: white;
}

.pic-back {
  width: 10px;
  height: auto;
  align-items: center;
}

.box-2-2 {
  width: 100%;
}

.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}

.back2-pick {
  width: 20px;
  height: 20px;
}

.center {
  display: flex;
  align-items: center;
  font-size: 8px;
}
</style>