<template>
  <div class="BOX" style="margin: 0px; padding: 0px">
    <div class="one-box3">
      <div class="one-box2-box" >
        <button>
          <img
            src="../assets/img/lblack.png"
            alt=""
            class="pic-back"
            @click="back"
          />
        </button>
      </div>
      <div class="one-box2-box">
        <p>{{language.a}}</p>
      </div>
      <div></div>
    </div>

    <div class="MessageBox">
      <p @click="gotoLatestAnnouncement2">{{language.b}}</p>
      <button @click="gotoLatestAnnouncement2">
        <img src="../assets/img/returnr.png" alt="" style="" />
      </button>
    </div>
    <Menu></Menu>
  </div>
</template>
 
 <script>
 import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu,
  },
  methods: {
    gotoLatestAnnouncement2() {
      this.$router.push("/LatestAnnouncement2");
    },
    Modifylogin() {
      this.$router.push("/Modifylogin");
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
  },

  data() {
    return {
      items: [
        {
          name: "555,",
          name1: "666",
          name2: "777",
          name3: "888",
          name4: "999",
          name5: "000",
        },
      ],
      // 翻译数组
      language: {
        a: "最新公告",
        b: "公司简介",
      },
      languageChinese: {
        a: "最新公告",
        b: "公司简介",
      },
      languageEnglish: {
        a: "Latest Announcements",
        b: "Company Introduction",
      },
      //繁体中文数组
      languageJT: {
        a: "最新公告",
        b: "公司簡介",
      },
      //泰语数组
      languageTY: {
        a: "ประกาศล่าสุด",
        b: "ข้อมูลบริษัท",
      },

      //韩语数组
      languageHY: {
        a: "최신 공지사항",
        b: "회사 프로필",
      },

      //日语数组
      languageRY: {
        a: "最新のお知らせ",
        b: "会社概要",
      },
      languageYDY: {
        a: "नवीनतम घोषणा",
        b: "कंपनी परिचय",
      },

      //印度语数组
    };
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
  },
};
</script>
 
 <style scoped>
 img{
  padding-left: 10px;
 }
body {
  padding: 0px;
  margin: 0px;
}
.MessageBox {
  width: 100%;
  height: 7%;
  border-bottom: 0.5px solid rgb(159, 152, 152);
  display: flex;
  justify-content: space-between;
  /* flex-direction:column */
}
.MessageBox img{
  width: 25px;
  height: 25px;
  /* border: 1px solid red; */
  margin-top: 10px;
}
.MessageBox p {
  width: 30%;
  height: 100%;
  /* border:1px solid rebeccapurple; */
  margin-left: 1%;
  margin-top: 10px;
  color: rgb(159, 152, 152);
}
.MessageBox button {
  width: 10%;
  height: 100%;
  border: none;
  background-color: rgb(217, 222, 222);
  /* background-image:url("../assets/imgs/returnr.png"); */
}
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-top:-4px; */
  width: 100%;
  min-height: 100vh;
  /* margin-top: 0px; */
  /* border-radius:10px 10px 10px 10px; */
  background-color: rgb(217, 222, 222);
}

.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;

  background-color: rgb(217, 222, 222);
}
.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
}
.one-box2 {
  display: flex;
  width: 100%;
}
.one-box3 {
  display: flex;
  /* space-around:space-evenly; */
  justify-content: space-between;
  width: 100%;

  padding: 10px 0;
  background-color: rgb(255, 255, 255);
}

.one-box2-box {
  width: 25%;
  height: 100%;
  /* border:1px solid red; */
  display: fixed;
  justify-content: space-between;
  /* margin-right:35%; */
}
.one-box2-box p {
  width: 100%;
  height: 100%;
  /* border:1px solid red; */
  text-align: left;
  margin: 0px 0px 0px 0px;
}
.one-box2-box button {
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  background-color: white;
}
.pic-back {
  width: 10px;
  height: auto;
}
.box-2-2 {
  width: 100%;
}
.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}
.back2-pick {
  width: 20px;
  height: 20px;
}
.center {
  display: flex;
  align-items: center;
  font-size: 8px;
}
</style>